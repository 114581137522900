<template>
  <nav class="sticky-top">
      <ul class="sideBar">
        <li>
          <router-link class="home-link" to="/">
            <img src="../assets/image/Icon/Arrow-Left.svg" alt="">
          </router-link>
        </li>
        <li class="main-link">
          <a class="js-link" href="#" data-link="#welcome" @click.prevent="scrollInto">
            歡迎<img src="../assets/image/Icon/Arrow-Right.svg" alt="">
          </a>
        </li>
        <li class="main-link">
          <a class="js-link" href="#" data-link="#brand" @click.prevent="scrollInto">
            介紹<img src="../assets/image/Icon/Arrow-Right.svg" alt="">
          </a>
        </li>
        <li class="main-link">
          <a class="js-link" href="#" data-link="#guideline" @click.prevent="scrollInto">
            規範<img src="../assets/image/Icon/Arrow-Right.svg" alt="">
          </a>
        </li>
        <li class="main-link">
          <a class="js-link" href="#" data-link="#download" @click.prevent="scrollInto">
            資源下載<img src="../assets/image/Icon/Arrow-Right.svg" alt="">
          </a>
        </li>
        <li class="main-link">
          <a class="js-link" href="#" data-link="#contact" @click.prevent="scrollInto">
            聯繫我們<img src="../assets/image/Icon/Arrow-Right.svg" alt="">
          </a>
        </li>
      </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollInto(e) {
      const linkBlock = document.querySelector(e.target.getAttribute('data-link'));
      linkBlock.scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/all';

.sideBar {
  .home-link {
    display: block;
    margin-bottom: 16px;
    padding: 16px 16px 16px 0;
    @include media-breakpoint-xl {
      margin-bottom: 48px;
    }
    @include media-breakpoint-xxl {
      margin-bottom: 56px;
    }
  }

  .main-link {
    display: none;
    @include media-breakpoint-xl {
      display: block;
      margin-bottom: 48px;
    }
    @include media-breakpoint-xxl {
      margin-bottom: 64px;
    }

    a {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: $line-height-sm;
      color: $gray;
      transition: $animation;
      padding: 16px 16px 16px 0;
      @include media-breakpoint-xxl {
        font-size: $font-size-md;
      }
      &:hover {
        color: $info;

        img {
          opacity: 1;
          transform: translate(8px, 6px);
        }
      }
    }

    img {
      width: 24px;
      opacity: 0;
      transform: translate(0, 6px);
      transition: $animation;
    }
  }
}
</style>
